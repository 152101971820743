import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  Stack,
  useColorModeValue,
  useDisclosure,
  Image,
  HStack,
  Text,
  Fade,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon, PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import {
  FaWhatsapp,
  FaSquareXTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa6";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

const Nav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const bgColor = useColorModeValue(
    scrolled ? "rgba(255, 255, 255, 0.9)" : "rgba(255, 255, 255, 0.8)",
    "rgba(26, 32, 44, 0.9)"
  );

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <Box>
      <Flex
        bg={bgColor}
        color={useColorModeValue("gray.800", "white")}
        minH={scrolled ? "70px" : "80px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        transition="all 0.3s ease"
        boxShadow={scrolled ? "sm" : "none"}
        backdropFilter="blur(5px)"
      >
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "space-between" }}
          align={"center"}
          width="100%"
        >
          <IconButton
            onClick={onOpen}
            icon={<HamburgerIcon w={5} h={5} />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
            display={{ base: "flex", md: "none" }}
            position="absolute"
            left={4}
          />

          <Flex justify="center" align="center" flex={{ base: 1, md: "auto" }}>
            <Image
              src="/logo.png"
              alt="Maggie Safari Ventures Logo"
              h={scrolled ? "50px" : "70px"}
              transition="all 0.3s ease"
            />
          </Flex>

          <HStack
            spacing={8}
            alignItems={"center"}
            justify={"center"}
            flex={1}
            display={{ base: "none", md: "flex" }}
          >
            <DesktopNav location={location} />
          </HStack>

          <Flex
            display={{ base: "none", md: "flex" }}
            justify={"flex-end"}
            align={"center"}
          >
            <Fade in={scrolled} transition={{ enter: { duration: 0.3 } }}>
              <HStack spacing={4} display={{ base: "none", md: "flex" }}>
                <Text fontSize="sm" whiteSpace="nowrap" fontWeight="medium">
                  <PhoneIcon mr={2} />
                  <a href="tel:+256701271772">+256-701-271-772</a>
                </Text>
                <Text fontSize="sm" whiteSpace="nowrap" fontWeight="medium">
                  <EmailIcon mr={2} />
                  <a href="mailto:info@maggiesafari.com">
                    info@maggiesafari.com
                  </a>
                </Text>
              </HStack>
            </Fade>

            <Stack direction={"row"} spacing={2} align={"center"} ml={4}>
              <SocialButton
                icon={FaWhatsapp}
                href="https://wa.me/256781500207"
              />
              <SocialButton
                icon={FaSquareXTwitter}
                href="https://x.com/maggiesafaris"
              />
              <SocialButton
                icon={FaInstagram}
                href="https://www.instagram.com/maggiesafarisventures"
              />
              <SocialButton
                icon={FaLinkedin}
                href="https://www.linkedin.com/in/maggie-safaris-ventures-911420331"
              />
            </Stack>
          </Flex>
        </Flex>
      </Flex>

      <MobileNav
        isOpen={isOpen}
        onClose={onClose}
        handleNavigation={handleNavigation}
      />
    </Box>
  );
};

const SocialButton = ({ icon: Icon, href }) => {
  return (
    <IconButton
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Social media"
      icon={<Icon />}
      size="sm"
      fontSize="lg"
      color={"white"}
      bg={"green.500"}
      _hover={{
        bg: "green.600",
      }}
    />
  );
};

const DesktopNav = ({ location }) => {
  const linkColor = useColorModeValue("gray.800", "white");
  const linkHoverColor = useColorModeValue("green.600", "green.200");
  const linkActiveColor = useColorModeValue("green.700", "green.300");

  return (
    <>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Button
            as={RouterLink}
            to={navItem.href}
            p={2}
            fontSize={"sm"}
            fontWeight={600}
            color={
              location.pathname === navItem.href ? linkActiveColor : linkColor
            }
            bg="transparent"
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
            borderBottom={location.pathname === navItem.href ? "2px" : "0"}
            borderColor={"green.500"}
          >
            {navItem.label}
          </Button>
        </Box>
      ))}
    </>
  );
};

const MobileNav = ({ isOpen, onClose, handleNavigation }) => {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch" mt={4}>
            {NAV_ITEMS.map((navItem) => (
              <Button
                key={navItem.label}
                onClick={() => handleNavigation(navItem.href)}
                variant="ghost"
                justifyContent="flex-start"
                fontWeight={600}
              >
                {navItem.label}
              </Button>
            ))}
          </VStack>
          <VStack spacing={4} mt={8}>
            <Text fontWeight="bold">Contact Us</Text>
            <Text>
              <PhoneIcon mr={2} />
              <a href="tel:+256701271772">+256-701-271-772</a>
            </Text>
            <Text>
              <EmailIcon mr={2} />
              <a href="mailto:info@maggiesafari.com">info@maggiesafari.com</a>
            </Text>
          </VStack>
          <HStack justify="center" mt={8} spacing={4}>
            <SocialButton icon={FaWhatsapp} href="https://wa.me/256781500207" />
            <SocialButton
              icon={FaSquareXTwitter}
              href="https://twitter.com/your-twitter-handle"
            />
            <SocialButton
              icon={FaInstagram}
              href="https://instagram.com/your-instagram-handle"
            />
            <SocialButton
              icon={FaLinkedin}
              href="https://linkedin.com/in/your-linkedin-profile"
            />
          </HStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const NAV_ITEMS = [
  { label: "Home", href: "/" },
  { label: "Destinations", href: "/destinations" },
  { label: "Safaris", href: "/Itineraries" },
  { label: "Get Quote", href: "/quote" },
  { label: "About Us", href: "/about" },
  { label: "Contact", href: "/contact" },
];

export default Nav;
