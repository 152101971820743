import React, { Suspense, lazy, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ChakraProvider, Box, Spinner } from "@chakra-ui/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { AnimatePresence } from "framer-motion";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import useDrift from "./hooks/useDrift";

// Lazy load page components
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Destinations = lazy(() => import("./pages/Destinations"));
const Itineraries = lazy(() => import("./pages/Itineraries"));
const Quote = lazy(() => import("./pages/Quote"));
const Contact = lazy(() => import("./pages/Contact"));

// Loading component
const Loading = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <Spinner size="xl" color="green.500" />
  </Box>
);

const AnimatedRoutes = ({ setIsFullScreenView }) => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<Loading />}>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={<Home setIsFullScreenView={setIsFullScreenView} />}
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/destinations"
            element={<Destinations setIsFullScreenView={setIsFullScreenView} />}
          />
          <Route path="/itineraries" element={<Itineraries />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

const App = () => {
  useDrift();
  const [isFullScreenView, setIsFullScreenView] = useState(false);

  return (
    <ChakraProvider>
      <Router>
        <ScrollToTop />
        <Box minHeight="100vh" display="flex" flexDirection="column">
          {!isFullScreenView && <Nav />}
          <Box flex="1">
            <AnimatedRoutes setIsFullScreenView={setIsFullScreenView} />
          </Box>
          {!isFullScreenView && <Footer />}
        </Box>
      </Router>
    </ChakraProvider>
  );
};

export default App;
